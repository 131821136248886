<template>
  <div class="page-promotion">
    <div class="search-box">
      <date-picker @change="getDate"></date-picker>
      <channel-select @change="getChannel"></channel-select>
      <el-button style="margin-left: 12px" size="small" type="primary" @click="search" >查询</el-button>
    </div>
    <div class="table-box">
      <table>
        <thead>
          <tr>
            <th>用户账号</th>
            <th style="width: 300px;">购买项目</th>
            <th>支付金额</th>
            <th>支付时间</th>
            <th v-if="!isOnly" style="width: 300px;">推广名称</th>
            <th v-if="!isOnly">推广ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in list" :key="i">
            <td >{{val.username}}</td>
            <td>{{val.remark}}</td>
            <td>¥{{val.payAmount}}</td>
            <td>{{getTime(val.paymentTime)}}</td>
            <td v-if="!isOnly">{{val.channelName}}</td>
            <td v-if="!isOnly">{{val.channelId}}</td>
          </tr>
        </tbody>
      </table>
      <div class="no-data" v-if="list.length === 0" >
        暂无数据
      </div>
    </div>
    <div class="page-box" v-if="list.length > 0">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="prev, pager, next, sizes"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getPayOrder} from '@/api'
import datePicker from '@/components/datePicker'
import channelSelect from '@/components/channelSelect'
import moment from 'moment'
export default {
  name: "payOrder",
  data(){
    return {
      date: [],
      channel: "",
      isOnly: false,
      list: [],
      page: 1,
      pageSize: 20,
      total: 0
    }
  },
  components: {
    datePicker, channelSelect
  },
  mounted(){
    
  },
  methods: {
    getTime(time){
      if(!time){
        return "-"
      }
      return moment(time).format("YYYY-MM-DD HH:mm")
    },
    search(){
      this.getList()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.page = 1
      this.search()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val
      this.search()
    },
    getList(){
      getPayOrder({
        channelId: this.channel,
        endDate: this.date[1] + " 23:59:59",
        startDate: this.date[0] + " 00:00:00",
        limit: this.pageSize,
        page: this.page
      }).then(resp => {
        this.isOnly = this.channel ? true : false
        this.list = resp.list
        this.total = resp.count
      })
    },
    getDate(val){
      this.date = val
    },
    getChannel(val){
      this.channel = val
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.page-promotion{
  .search-box{

  }
  .table-box{
    margin-top: 24px;
    table{
      width: 100%;
      @include tableBorder;
      border: 1px solid #e7e7e7;
      td,th{
        height: 49px;
        text-align: center;
      }
      td{
        background: #fff;
        border-top: 1px solid #e7e7e7;
      }
      th{
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        opacity: 0.89;
        background: #f7f7f7;
      }
    }
    .no-data{
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-top: none;
    }
  }
  .page-box{
    padding: 20px 0 ;
    text-align: center;
  }
}
</style>