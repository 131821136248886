import request from './request'
// import { createlog } from './qruser'
const BATH_PATH = process.env.VUE_APP_BASE_BEAUTIFY_API
/**
 * 二维码美化
 * @param {} data
 */
export function styleCreate(params) {
  const p = request({
    url: '/beautify/style/create',
    method: 'get',
    params: params,
    // responseType: 'arraybuffer',
    baseURL: BATH_PATH
  })

  return p
}

/**
 * 保存为模板
 * @param {} data
 */
export function saveTemplate(data) {
  return request({
    url: '/beautify/style/save_template',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

