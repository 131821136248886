<template>
  <div class="page-data">
    <el-row class="top-row" :gutter="20">
      <el-col  :span="8">
        <div class="box num-box" @click="toPromotion">
          <el-popover
            placement="right"
            title=""
            width="200"
            trigger="hover"
            class="intro-tip"
            :content="time+'账号推广的总用户数'">
            <span class="icon-intro" slot="reference"></span>
          </el-popover>
          <p>推广用户数<i class="el-icon-arrow-right"></i></p>
          <div>
            <animated-number :value="spreadCount" :duration="1000" :round="1" />
          </div>
        </div>
      </el-col>
      <el-col  :span="8">
        <div class="box num-box" @click="toPromotion">
          <el-popover
            placement="right"
            title=""
            width="200"
            trigger="hover"
            class="intro-tip"
            :content="time+'账号推广的总注册用户数'">
            <span class="icon-intro" slot="reference"></span>
          </el-popover>
          <p>注册用户数<i class="el-icon-arrow-right"></i></p>
          <div>
            <animated-number :value="registerCount" :duration="1000" :round="1" />
          </div>
        </div>
      </el-col>
      <el-col  :span="8">
        <div class="box num-box" @click="toPay">
          <el-popover
            placement="right"
            title=""
            width="200"
            trigger="hover"
            class="intro-tip"
            :content="time+'账号推广的用户首次支付的累计金额'">
            <span class="icon-intro" slot="reference"></span>
          </el-popover>
          <p>支付金额<i class="el-icon-arrow-right"></i></p>
          <div>
            <span>¥</span><animated-number :value="payAmount" :duration="1000" :round="1" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="main-row">
      <el-col>
        <div class="box">
          <line-chart v-if="scanNoData === 0" :options="scanData"></line-chart>
          <div v-if="scanNoData === 1" class="no-data">
            <img src="@/assets/nodata.png" alt="">
            <p>暂无推广统计数据</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="main-row">
      <el-col>
        <div class="box">
          <line-chart v-if="payNoData === 0" :options="payData"></line-chart>
          <div v-if="payNoData === 1" class="no-data">
            <img src="@/assets/nodata.png" alt="">
            <p>暂无支付统计数据</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>  
</template>

<script>
import {getMainInfo} from '@/api'
import animatedNumber from 'animated-number-vue'
import moment from 'moment'
import lineChart from '@/components/lineChart'
export default {
  name: 'page-data',
  components: {
    animatedNumber,
    lineChart
  },
  data(){
    return {
      payAmount: 0,
      registerCount: 0,
      spreadCount: 0,
      weChatCount: 0,
      batchTemplateCount: 0,
      scanNoData: 2,
      payNoData: 2,
      scanData: {},
      payData: {},
      date: [],
      time: '',
      end: 0,
    }
  },
  mounted(){
    let day = moment().format("DD")
    let date = []
    
    if(parseInt(day) === 1){
      let lastMonth = moment().subtract(1, 'months')
      let end = lastMonth.daysInMonth()
      this.end = end
      let YM = lastMonth.format('YYYY-MM')
      date = [YM + "-01", YM + "-" + end ]
      this.time = moment().format('YYYY年MM月')
    }else{
      this.end = day
      let YM = moment().format('YYYY-MM')
      date = [YM + "-01", YM + "-" + day]
      this.time = moment().format('YYYY年MM月')
    }
    this.date = date

    this.getData()
  },
  methods: {
    toPromotion(){
      this.$router.push('/promotion')
    },
    toPay(){
      this.$router.push('/payAmount')
    },
    toGroup(){
      this.$router.push('/group')
    },
    toCode(){
      this.$router.push('/code')
    },
    toTemplate(){
      this.$router.push('/beauty')
    },
    getData(){
      getMainInfo().then(resp => {
        Object.assign(this._data, resp)
        let spread = []
        let register = []
        let pay = []
        let x = []

        resp.payList = resp.payList ? resp.payList : []
        resp.spreadList = resp.spreadList ? resp.spreadList : []

        for(let i = 0; i < this.end - 1; i++){
          x.push(moment(this.date[0]).add(i, 'days').format("YYYYMMDD"))
        }
        
        x.map(val => {
          let data = resp.spreadList.find(d => d.dt == val)
          let pays = resp.payList.find(d => d.dt == val)
          if(data){
            spread.push(data.visitSum)
            register.push(data.incUserSum)
          }else{
            spread.push(0)
            register.push(0)
          }

          if(pays){
            pay.push(pays.payAmountSum)
          }else{
            pay.push(0)
          }
          
        })

        x = x.map(val => {
          let str = val.substr(4,7)
          return `${str.slice(0,2)}-${str.slice(2)}`
        })


        this.scanData = {
          xAxis: {data: x},
          leagend: {
            data: ["推广人数","注册人数"]
          },
          series: [
            {
              name: '推广人数',
              symbol: 'circle',
              symbolSize: 6,
              type: 'line',
              data: spread
            },
            {
              name: '注册人数',
              type: 'line',
              symbolSize: 8,
              symbol: 'diamond',
              data: register
            }
          ]
        }
        this.scanNoData = 0
        this.payData = {
          xAxis: {data: x},
          leagend: {
            data: ["支付金额"]
          },
          series: [
            {
              name: '支付金额',
              symbol: 'circle',
              symbolSize: 6,
              type: 'line',
              data: pay
            },
          ]
        }
        this.payNoData = 0
        
        

        

      }).catch(() => {
        console.log("err")
      })
    },
    getM(num){
      return Number((num/1024).toFixed(2))
    }
  }
}
</script>

<style lang="scss">
.page-data{
  line-height: 1;
  .box{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #fff;
  }
  .num-box{
    cursor: pointer;
    .intro-tip{
      position: absolute;
      right: 10px;
      top: -15px;
      margin: 0;
      padding: 0;
    }
    .icon-intro{
      color: #999;
      font-size: 22px;
    }
  }
  .top-row{
    text-align: center;
    .el-col{
      height: 110px;
    }
    p{
      padding-top: 30px;
      font-size: 15px;
      color: #666;
      font-weight: 400;
      i{
        font-weight: bold;
        color: #999;
      }
    }
    span{
      display: inline-block;
      margin-top: 10px;
      font-size: 28px;
      color: $primary;
      font-weight: 500;
    }
  }
  .main-row{
    margin-top: 20px;
    .el-col{
      height: 430px;
    }
    .echarts{
      width: 100%;
      height: 100%;
    }
  }
  .bottom-row{
    margin-top: 20px;
    .el-col{
      height: 325px;
    }
    h3{
      position: absolute;
      left: 0;
      top: 28px;
      width: 100%;
      text-align: center;
      font-size: 17px;
      font-weight: 500;
    }
    .echarts{
      width: 100%;
      height: 100%;
    }
  }

  .no-data{
    @include abCenter;
    text-align: center;
    img{
      display: block;
      margin: 0 auto auto auto;
      width: 67px;
    }
    font-size: 14px;
    font-weight: 400;
    color: #B2B2B2;
    p{
      margin-top: 12px;
    }
  }
  
}
</style>