<template>
  <el-date-picker
    v-model="date"
    size="small"
    type="daterange"
    range-separator="至"
    @change="change"
    value-format="yyyy-MM-dd"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :clearable="false"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
import moment from 'moment'
export default {
  name: "datePicker",
  props: {
    max: {
      type: Number,
      default: 181
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data(){
    return {
      date: []
    }
  },
  computed: {
    pickerOptions(){
      let self = this
      return {
        onPick: ({ maxDate, minDate }) => {        
         this.minDate = minDate
         this.maxDate = maxDate
        },
        disabledDate: (time) => { //查询时间跨度为180天
          if(this.minDate){
            let range = self.max * 24 * 3600 * 1000
            return time.getTime() > Date.now() || time.getTime() > (this.minDate.getTime() + range) || time.getTime() < (this.minDate.getTime() - range)
          }
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: '最近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  mounted(){
    let day = moment().format("DD")
    let date = []
    if(parseInt(day) === 1){
      let lastMonth = moment().subtract(1, 'months')
      let end = lastMonth.daysInMonth()
      let YM = lastMonth.format('YYYY-MM')
      date = [YM + "-01", YM + "-" + end ]
    }else{
      let end
      if(this.type === 2){
        end = moment().subtract(1, 'days').format("DD")
      }else{
        end = moment().format("DD")
      }
      
      let YM = moment().format('YYYY-MM')
      date = [YM + "-01", YM + "-" + end]
    }
    this.date = date
    this.$emit("change", date)
  },
  methods: {
    change(val){
      this.$emit("change", val)
    }
  }
}
</script>

<style lang="scss">

</style>
