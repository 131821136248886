<template>
  <el-dialog
    class="qr-dailog-wrapper"
    :visible="visible"
    width="1200px"
    :top="top"
    :lock-scroll="true"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="handleOnCancle"
  >
    <div class="qr-dialog-body">

      <el-row>
        <el-col :span="16" style="position:relative;">
          <!-- v-model="activeName" @tab-click="handleClick" -->
          <div v-if="content==''" class="dialog-mask" @click="showPrompt('请先编辑二维码内容！')" />
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane class="mb-panel" label="美化模板" name="0">
              <el-row style="width: 440px;margin: 20px auto 5px auto;">
                <el-col
                  v-for="(item, index) in [
                    '我的模板',
                    '经典样式',
                    '简约样式',
                    '黑白样式'
                  ]"
                  :key="index"
                  :span="6"
                >
                  <div
                    class="mb-nav-item"
                    :class="{ active: mbNavItemIndex === index }"
                    @click="mbNavItemIndex=index"
                  >
                    {{ item }}
                  </div>
                </el-col>
              </el-row>
              <el-row v-show="mbNavItemIndex==0" class="mb-row scroll-bar">
                <template v-if="showMobile">
                  <el-col v-for="(item, index) in tpList" :key="index" :span="6">
                    <div class="mb-item" :class="$store.state.templateIndex === index ? 'active' : ''" @click="handleChangeMb(item,$event)">
                      <i v-if="isEditMb===true" class="el-icon-close" @click.stop="handleChangeDeleteMb(item,$event)" />
                      <img :src="item.displayImage" alt="二维码美化模板-我的模板">
                    </div>
                  </el-col>
                  <div v-if="tpList.length===0" style="text-align:center;padding-top:75px;">
                    <div class="empty-list" />
                    <p style="font-size:14px;color:#666;margin-top: 30px;margin-bottom: 20px;">还未保存过我的模板</p>
                  </div>
                </template>
                <div v-else style="text-align:center;padding-top:75px;">
                  <div class="empty-list" />
                  <p style="font-size:14px;color:#666;margin-top: 30px;margin-bottom: 20px;">登录后才能查看我的模板</p>
                  <el-button plain type="primary" style="width:120px; font-size: 14px" size="small" @click.prevent.stop="showDialogWin">登录</el-button>
                </div>

              </el-row>
              <!-- <div v-show="showMobile&&mbNavItemIndex==0">
                <div v-if="tpList.length>0" style="text-align: center;margin-top:12px;">
                  <span v-if="isEditMb===false" class="edit-template-oper" @click.prevent.stop="isEditMb=true">编辑我的模板</span>
                  <el-button v-if="isEditMb===true" plain type="primary" style="width:120px" size="small" @click.prevent.stop="isEditMb=false">退出编辑</el-button>
                </div>

              </div> -->
              <el-row v-show="mbNavItemIndex==1" class="mb-row scroll-bar">
                <el-col v-for="(item, index) in templateList5" :key="index" :span="6">
                  <div class="mb-item" @click="handleChangeMb(item,$event)">
                    <img :src="item.displayImage" alt="二维码美化模板-经典样式">
                  </div>
                </el-col>
              </el-row>
              <el-row v-show="mbNavItemIndex==2" class="mb-row scroll-bar">
                <el-col v-for="(item, index) in templateList6" :key="index" :span="6">
                  <div class="mb-item" @click="handleChangeMb(item,$event)">
                    <img :src="item.displayImage" alt="二维码美化模板-简约样式">
                  </div>
                </el-col>
              </el-row>
              <el-row v-show="mbNavItemIndex==3" class="mb-row scroll-bar">
                <el-col v-for="(item, index) in templateList7" :key="index" :span="6">
                  <div class="mb-item" @click="handleChangeMb(item,$event)">
                    <img :src="item.displayImage" alt="二维码美化模板-黑白样式">
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane class="logo-panel" label="上传logo" name="10">
              <div class="title">
                选择logo
              </div>
              <el-row style="width: 710px;">
                <el-col :span="3">
                  <div
                    class="logo-item"
                    :class="{ active: qrParams.logoId == -1 }"
                    @click="handleChangleLogo(-1, '')"
                  >
                    <i class="iconfont">&#xe615;</i>
                    <p>无logo</p>
                  </div>
                </el-col>
                <el-col :span="3">
                  <div class="logo-item" :class="{ active: qrParams.logoId == -2 }" @click="handleChangleCustomLogo">
                    <template v-if="qrParams.uploadLogoUrl">
                      <img :src="qrParams.uploadLogoUrl" alt="二维码美化-本地上传logo">
                      <i class="el-icon-close" @click.stop="handleClearLogo" />
                    </template>
                    <template v-else>
                      <el-upload
                        :action="filePath"
                        :show-file-list="false"
                        :on-success="handleUploadLogo"
                        :on-progress="handleProgressLogo"
                        :before-upload="beforeLogoUpload"
                        accept="image/jpeg,image/png,image/bmp,image/jpg"
                      >
                        <el-progress v-if="logoPercentage>0&&logoPercentage<=100" color="#29a158" type="circle" :stroke-width="4" :percentage="logoPercentage" :width="48" style="position: absolute;    background: #fff;left: 8px; top: 8px;" />
                        <i class="iconfont upload">&#xe60d;</i>
                        <p>本地上传</p>
                      </el-upload>
                    </template>
                  </div>

                </el-col>
                <el-col
                  v-for="logoItem in logoList"
                  :key="logoItem.typeId"
                  :span="3"
                >
                  <div
                    class="logo-item"
                    :class="{ active: qrParams.logoId == logoItem.typeId }"
                    @click="
                      handleChangleLogo(logoItem.typeId, logoItem.imageSrc,logoItem.displayImageSrc)
                    "
                  >
                    <img :src="logoItem.displayImageSrc" alt="二维码美化-选择logo">
                  </div>
                </el-col>
              </el-row>
              <div v-if="qrParams.logoId != -1">
                <div class="title" style="margin-top:5px;">
                  logo效果
                </div>
                <el-row style="width: 710px;" class="effect-row">
                  <el-col :span="3">
                    <div
                      class="logo-item logo-effect-item"
                      :class="{ active: qrInfo.logoShap == 0 }"
                      @click="qrInfo.logoShap = 0"
                    >
                      <img
                        :src="qrParams.logoDisplayUrl"
                        alt="logo效果-原图"
                      >
                      <p style="    margin-top: 2px;">原图</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div
                      class="logo-item logo-effect-item"
                      :class="{ active: qrInfo.logoShap == 1 }"
                      @click="qrInfo.logoShap = 1"
                    >
                      <img
                        :src="qrParams.logoDisplayUrl"
                        alt="logo效果-矩形描边"
                      >
                      <p style=" margin-top: 2px;">矩形描边</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div
                      class="logo-item logo-effect-item"
                      :class="{ active: qrInfo.logoShap == 2 }"
                      @click="qrInfo.logoShap = 2"
                    >
                      <img
                        :src="qrParams.logoDisplayUrl"
                        alt="logo效果-圆形描边"
                      >
                      <p style=" margin-top: 2px;">圆形描边</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="qrParams.logoId != -1">
                <!-- <div class="title" style="margin-top:5px;">
                  logo投影
                </div>
                <el-row style="width: 710px;" class="shadow-row">
                  <el-col :span="3">
                    <div
                      class="logo-item logo-shadow-item"
                      :class="{ active: qrInfo.logoShadow == 0 }"
                      @click="qrInfo.logoShadow = 0"
                    >
                      <img :src="qrParams.logoDisplayUrl" alt="logo投影-无投影">
                      <p style=" margin-top: 2px;">无投影</p>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div
                      class="logo-item logo-shadow-item"
                      :class="{ active: qrInfo.logoShadow == 1 }"
                      @click="qrInfo.logoShadow = 1"
                    >
                      <img :src="qrParams.logoDisplayUrl" alt="logo投影-投影">
                      <p style="    margin-top: 2px;">投影</p>
                    </div>
                  </el-col>
                </el-row> -->
              </div>
            </el-tab-pane>
            <el-tab-pane key="color-panel" class="color-panel" label="二维码颜色" name="20">
              <div style="margin-bottom: 20px;" />
              <el-radio-group
                key="qrParams.foreType"
                v-model="qrParams.foreType"
                @change="handleChangeForeType"
              >
                <el-radio :label="0">纯色</el-radio>
                <el-radio :label="1">渐变色</el-radio>
                <el-radio :label="2">前景图填充</el-radio>
              </el-radio-group>
              <div v-show="qrParams.foreType != 2">
                <div class="title">
                  推荐颜色
                </div>
                <el-row style="width: 420px;">
                  <el-col
                    v-for="colorItem in configColorList"
                    :key="colorItem"
                    :span="3"
                  >
                    <div
                      class="color-item"
                      :class="{ active: isForeColorActive(colorItem) }"
                      @click="handleChangeForeColor(colorItem)"
                    >
                      <div
                        class="content"
                        :style="`background:${getBgColor(colorItem)}`"
                      />
                    </div>
                  </el-col>
                </el-row>
                <div style="margin-bottom:10px;" />
                <div class="title">
                  自定义颜色
                </div>
                <div>
                  <div v-show="qrParams.foreType == 0" class="color-item" :class="{active:qrParams.foreColor}">
                    <el-color-picker
                      :value="qrParams.foreColor"
                      color-format="hex"
                      @input="handleChangeCustomForeColor"
                    />
                  </div>
                  <div v-show="qrParams.foreType == 1" class="color-item" :class="{active:qrParams.foreColorFirst}">
                    <el-color-picker
                      :value="qrParams.foreColorFirst"
                      color-format="hex"
                      @input="handleChangeCustomForeColor1"
                    />
                  </div>
                  <div v-show="qrParams.foreType == 1" class="color-item" style="margin-left:5px;" :class="{active:qrParams.foreColorTwo}">
                    <el-color-picker
                      v-show="qrParams.foreType == 1"
                      :value="qrParams.foreColorTwo"
                      color-format="hex"
                      @input="handleChangeCustomForeColor1($event,'foreColorTwo')"
                    />
                  </div>

                </div>
                <div
                  v-show="qrParams.foreType == 1"
                  style="font-size:13px;color:#999999;margin-bottom:20px;margin-top:5px"
                >
                  <span
                    style=" margin-left: 7px; margin-right: 18px;"
                  >颜色1</span>
                  <span>颜色2</span>
                </div>
                <div style="margin-bottom: 15px;" />
                <div v-show="qrParams.foreType == 1">
                  渐变方向：
                  <el-radio-group v-model="qrInfo.gradientWay">
                    <el-radio :label="0">垂直</el-radio>
                    <el-radio :label="1">水平</el-radio>
                    <el-radio :label="2">对角</el-radio>
                    <el-radio :label="3">中心</el-radio>
                  </el-radio-group>
                </div>
                <div style="margin-bottom:15px;" />

              </div>
              <div v-show="qrParams.foreType == 2">
                <div class="title">
                  推荐图库
                </div>
                <el-row>
                  <el-col :span="20">
                    <el-row>
                      <el-col
                        v-for="(foreItem, $index) in foreImageList"
                        v-show="
                          (foreImagePage - 1) * 14 < $index + 1 &&
                            $index + 1 <= foreImagePage * 14
                        "
                        :key="foreItem.typeId"
                        :span="3"
                        style="width:14.28%"
                      >
                        <div
                          class="fore-image-item"
                          :class="{
                            active: qrInfo.foreColorImage === foreItem.imageSrc
                          }"
                          @click="handleChangeForeImage(foreItem)"
                        >
                          <div class="content">
                            <img :src="foreItem.displayImageSrc" alt="二维码颜色-前景图填充">
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="4" style="position: relative;">
                    <div
                      class="pre-page"
                      :class="{ disabled: foreImagePage === 1 }"
                      @click="goForePage($event, -1)"
                    >
                      <i class="el-icon-arrow-up" />
                    </div>
                    <div
                      class="next-page"
                      :class="{
                        disabled: foreImagePage + 1 > foreImageListMaxPage
                      }"
                      @click="goForePage($event, 1)"
                    >
                      <i class="el-icon-arrow-down" />
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div>
                <el-checkbox
                  v-model="qrInfo.eyeUseFore"
                  :true-label="1"
                  :false-label="0"
                >{{ qrParams.foreType == 2?'应用到码眼':'把选择颜色应用到码眼' }}</el-checkbox>
              </div>
              <div style="margin-bottom:20px;" />
              <el-divider />
              <div style="margin-bottom:20px;" />
              <div class="title">
                背景色<span
                  style="font-size:13px; color:#999999;padding-left: 12px;"
                >建议背景色为浅色，二维码为深色，更容易扫描识别</span>
              </div>
              <div style="display: flex;">
                <div
                  class="bg-color-custom"
                  :class="{ active: qrInfo.bgColor !== '' }"
                  @click="qrInfo.bgColor = qrParams.bgColor"
                >
                  <el-color-picker
                    v-model="qrParams.bgColor"
                    color-format="hex"
                    style="transform: scale(.9);"
                    @change="qrInfo.bgColor = qrParams.bgColor"
                  />
                </div>
                <div
                  class="bg-color-transparency"
                  :class="{ active: qrInfo.bgColor === '' }"
                  @click="qrInfo.bgColor = ''"
                >
                  透明
                </div>
              </div>
              <div style="margin-bottom:10px;" />
              <div class="title">
                <span style="margin-right:10px;">码眼外</span><span>码眼内</span>
              </div>
              <div style="display: flex;">
                <div class="eye-color-item">
                  <el-color-picker
                    v-model="qrInfo.eyeOutColor"
                    color-format="hex"
                    @change="qrInfo.eyeUseFore = 0"
                  />
                </div>
                <div class="eye-color-item">
                  <el-color-picker
                    v-model="qrInfo.eyeInColor"
                    color-format="hex"
                    @change="qrInfo.eyeUseFore = 0"
                  />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane class="embed-text-panel" label="添加文字" name="30">
              <div style="margin-bottom:20px;margin-top:25px">
                <el-select
                  v-model="qrInfo.embedTextSize"
                  style="margin-right:14px;width:145px;"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in [18, 20, 24, 28, 30, 36,38,40,46]"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <el-select
                  v-model="qrInfo.fontFamily"
                  size="small"
                  style="width:145px;"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in [{ text: '黑体', value: 0 },{text: '宋体', value: 1 }]"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
                <div class="small-color-pick">
                  <el-color-picker
                    v-model="qrInfo.embedTextColor"
                    color-format="hex"
                  />
                </div>
              </div>
              <el-row :gutter="10">
                <!-- <el-col
                  v-for="(textItem, $index) in qrParams.embedTextList"
                  :key="$index"
                  :span="24"
                >
                  <el-input
                    v-model="qrParams.embedTextList[$index]"
                    style="width: 350px;margin-right: 10px;margin-bottom: 10px;"
                    size="small"
                    placeholder="请输入要添加的文字"
                    @blur="handleEmbedTextBlur"
                  />
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="small"
                    circle
                    @click="handleDeleteEmbedText($index)"
                  />

                  <el-button
                    v-if="$index === 0"
                    size="small"
                    @click="qrParams.embedTextList.push('')"
                  >再增加一行</el-button>
                </el-col> -->
                <el-col :span="24">
                  <el-input
                    v-model="qrParams.embedTextList[0]"
                    style="width: 346px;margin-right: 10px;margin-bottom: 10px;"
                    size="small"
                    placeholder="要添加的第一行文字"
                  />
                </el-col>
                <el-col :span="24">
                  <el-input
                    v-model="qrParams.embedTextList[1]"
                    style="width: 346px;margin-right: 10px;margin-bottom: 10px;"
                    size="small"
                    placeholder="要添加的第二行文字"
                  />
                  <el-button
                    plain
                    size="small"
                    @click="handleEmbedTextBlur"
                  >确定</el-button>
                </el-col>
              </el-row>
              <div class="title" style="margin-top: 14px;">
                文字位置
              </div>
              <el-radio-group v-model="qrInfo.embedPosition">
                <el-radio :label="0">码外部</el-radio>
                <el-radio :label="1">嵌入码中间</el-radio>
              </el-radio-group>
            </el-tab-pane>
            <el-tab-pane class="out-line-panel" label="外框" name="40">
              <div class="title">
                外框样式
              </div>
              <el-row>
                <el-col :span="21">
                  <el-row>
                    <el-col
                      v-for="(item, $index) in outLineList"
                      v-show="
                        (outLineImagePage - 1) * 14 < $index + 1 &&
                          $index + 1 <= outLineImagePage * 14
                      "
                      :key="item.typeId"
                      :span="3"
                      style="width:14.28%"
                    >
                      <div
                        v-if="item.typeId===null"
                        class="out-line-image-item"
                        :class="{
                          active: qrInfo.frameId === null
                        }"
                        @click="handleChangeOutLineImage({typeId:null})"
                      >
                        <div class="content">
                          <img src="./images/outline-empty.png" style="width: 55px;height: 55px;" alt="二维码美化-无边框">
                          <p style="margin-top: -3px;font-size: 10px;color: #666;">无边框</p>
                        </div>
                      </div>
                      <div
                        v-else
                        class="out-line-image-item"
                        :class="{
                          active: qrInfo.frameId === item.typeId
                        }"
                        @click="handleChangeOutLineImage(item)"
                      >
                        <div class="content">
                          <img :src="item.displayImageSrc" alt="二维码美化-外框">
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="3" style="position: relative;">
                  <div
                    class="pre-page"
                    :class="{ disabled: outLineImagePage === 1 }"
                    @click="goOutLinePage($event, -1)"
                  >
                    <i class="el-icon-arrow-up" />
                  </div>
                  <div
                    class="next-page"
                    :class="{
                      disabled: outLineImagePage + 1 > outLineImageListMaxPage
                    }"
                    @click="goOutLinePage($event, 1)"
                  >
                    <i class="el-icon-arrow-down" />
                  </div>
                </el-col>
              </el-row>
              <div class="title" style="margin-top: 10px;">
                外框颜色
              </div>
              <div style="height: 45px;">
                <div class="small-color-pick">
                  <el-color-picker
                    v-model="qrParams.frameColor"
                    color-format="hex"
                    @change="handleChangeFrameColor"
                  />
                </div>
                <el-checkbox
                  v-model="qrParams.frameColorFlag"
                  style="margin-left: 55px;margin-top: 5px;"
                  :true-label="1"
                  :false-label="0"
                  @change="handleChangeFrameColorFlag"
                >颜色应用到外框</el-checkbox>
              </div>

            </el-tab-pane>
            <el-tab-pane class="out-line-panel" label="码点" name="50">
              <div class="title">
                码点
              </div>
              <el-row style="padding-right: 95px;">
                <el-col v-for="item in bodyList" :key="item.typeId" style="width:14.28%">
                  <div
                    class="out-line-image-item"
                    :class="{
                      active: qrInfo.bodyType === item.typeId
                    }"
                    @click="qrInfo.bodyType = item.typeId"
                  >
                    <div class="content" style="padding:5px">
                      <img :src="item.displayImageSrc" alt="二维码美化-码点">
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="title" style="margin-top: 10px">
                码眼
              </div>
              <el-row style="width:648px;">
                <el-col v-for="item in eyeList" :key="item.typeId" :span="3">
                  <div
                    class="out-line-image-item"
                    :class="{
                      active: qrInfo.eyeType === item.typeId
                    }"
                    style="width:64.5px;height:64.5px;"
                    @click="qrInfo.eyeType = item.typeId"
                  >
                    <div class="content" style="width: 45px;height: 45px;">
                      <img :src="item.displayImageSrc" alt="二维码美化-码眼">
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane class="other-panel" label="其他设置" name="60">
              <el-row :gutter="10" style="margin-top: 25px;">
                <el-col :span="3">二维码边距：</el-col>
                <el-col :span="20">
                  <el-radio-group v-model="qrInfo.margin">
                    <el-radio :label="0">0个色块</el-radio>
                    <el-radio :label="1">1个色块</el-radio>
                    <el-radio :label="2">2个色块</el-radio>
                    <el-radio :label="3">3个色块</el-radio>
                    <el-radio :label="4">4个色块</el-radio>
                  </el-radio-group>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="20" :offset="3">
                  <p>维码图案距离四周的边距，QR码标准边距为2个色块。</p>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="3">容错率：</el-col>
                <el-col :span="20">
                  <el-radio-group v-model="qrInfo.level">
                    <el-radio label="L">最低(可遮7%)</el-radio>
                    <el-radio label="M">低(15%)</el-radio>
                    <el-radio label="Q">中等(25%)</el-radio>
                    <el-radio label="H">高(30%)</el-radio>
                  </el-radio-group>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="20" :offset="3">
                  <p>
                    二维码被遮挡后仍能被正常扫描，而这个能被遮挡的最大面积就是容错率。<br/>容错率越高，则二维码图片能被遮挡的部分越多。
                  </p>
                </el-col>
              </el-row>

              <el-row :gutter="10">
                <el-col :span="3">下载尺寸：</el-col>
                <el-col :span="20">
                  <el-radio-group v-model="qrInfo.size">
                    <el-radio :label="1000">宽度1000px</el-radio>
                    <el-radio :label="500">宽度500px</el-radio>
                    <el-radio :label="400">默认宽度400px</el-radio>
                    <el-radio :label="300">宽度300px</el-radio>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col
          :span="8"
          style="padding:80px 45px;background:rgba(244,244,244,1);height: 650px; "
        >
          <!-- <div style=" display:flex;margin-bottom: 20px;">
            <el-button
              plain
              style="width: 150px;"
              @click="editDialogVisible = true;curContent=content;"
            >编辑文字</el-button>
            <el-upload
              style="width: 150px; "
              action="#"
              :show-file-list="false"
              :on-change="handleOnChange"
              :auto-upload="false"
              :file-list="fileList"
              accept="image/jpeg,image/png,image/bmp,image/jpg"
            >
              <el-button
                plain
                style="width: 150px;margin-left: 10px;"
              >上传图片解码
              </el-button>
            </el-upload>
          </div> -->
          <qr-view  :qr-url="qrUrl" :loading="qrLoading" style="margin-bottom:20px;" />
          <el-button
            v-if="qrUrl"
            style="width: 310px;"
            type="primary"
            @click="handleSaveTemplate"
          >确定</el-button>
          <el-button type="default" style="width: 310px; margin-left: 0; margin-top: 20px;" plain  @click="reset">
            清除美化样式
          </el-button>
          <!-- <p
            v-if="qrUrl"
            style="font-size:14px;color:#666666;cursor:pointer;"
          >
            <span class="clear-style" @click="reset">清除美化样式</span>
            <span class="save-template" @click="handleSaveTemplate">保存为我的模板</span>
          </p> -->
        </el-col>
      </el-row>
    </div>
    <el-dialog
      class="edit-dailog-wrapper"
      :visible.sync="editDialogVisible"
      title="生成二维码"
      width="600px"
      :lock-scroll="true"
      :show-close="true"
      :append-to-body="true"
    >
      <div class="content-wrapper">
        <el-input
          v-model="curContent"
          type="textarea"
          placeholder="请输入文字内容"
          maxlength="150"
          show-word-limit
          resize="none"
        />
        <div style="text-align: center;padding-top:20px;">
          <el-button
            style="width: 100px;margin-bottom:13px;"
            type="primary"
            :disabled="submitContentDisabled"
            @click="submitContent"
          >确定</el-button>
        </div>
      </div>
    </el-dialog>
    <error-dialog :visible="errorDialogVisible" @on-cancle="handleCancleErrorDialogVisible" />
  </el-dialog>
</template>

<script>
import { downFile } from '@/utils'
import qrView from './qrView'
import { Decoder } from '@nuintun/qrcode'

// import { Encoder, Decoder, ErrorCorrectionLevel } from '@nuintun/qrcode'
import { getImageConfig, getTemplateConfig, getConfigColor, getMytplist, delTemplateByid, createlog } from '@/api/qruser'
import { styleCreate } from '@/api/beautify'

import errorDialog from '@/components/Message/errorDialog.vue'
// import defaultSettings from '@/settings'
let _ = window._
export default {
  components: {
    qrView,
    errorDialog
  },
  props: {
    templateId:{
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      top: "15vh",
      isFirst: true,
      curContent: '',
      qrLoading: false,
      isEditMb: false, // 编辑模板
      templateList5: [],
      templateList6: [],
      templateList7: [],
      fileList: [],
      editDialogVisible: false,
      errorDialogVisible: false,
      submitContentDisabled: false,
      activeName: '0',
      filePath: process.env.VUE_APP_BASE_FILE_API + '/file/simple',
      mbNavItemIndex: 1,
      imgConfigList: [],
      configColorAll: [],
      logoPercentage: 0,
      foreImagePage: 1,
      outLineImagePage: 1
    }
  },

  computed: {
    qrUrl: {
      get() {
        return this.$store.state.main.qrUrl
      },
      set(value) {
        this.$store.commit('main/SET_QR_URL', value)
      }
    },
    qrInfo: {
      get() {
        return this.$store.state.main.qrInfo
      },
      set(value) {
        this.$store.commit('main/SET_QR_INFO', value)
      }
    },
    qrParams: {
      get() {
        return this.$store.state.main.qrParams
      },
      set(value) {
        this.$store.commit('main/SET_QR_PARAMS', value)
      }
    },
    showMobile: {
      get() {
        return this.$store.state.user.showMobile
      }
    },
    content: {
      get() {
        return this.$store.state.main.content
      },
      set(value) {
        this.$store.commit('main/SET_CONTENT', value)
      }
    },
    tpList: {
      get() {
        return this.$store.state.main.tpList
      },
      set(value) {
        this.$store.commit('main/SET_TP_LIST', value)
      }
    },
    logoList() {
      return this.imgConfigList.filter(o => o.imageType === 4)
      // return this.imgConfigList.filter(o => o.displayImageSrc.indexOf('test/logo/') > -1)
    },
    configColorList() {
      if (this.qrParams.foreType === 0) {
        return this.configColorAll.filter(color => color.indexOf('-') < 0)
      } else if (this.qrParams.foreType === 1) {
        return this.configColorAll.filter(color => color.indexOf('-') >= 0)
      } else {
        return []
      }
    },
    foreImageList() {
      const imgList = this.imgConfigList.filter(o => o.imageType === 3)
      return imgList
    },
    foreImageListLength() {
      return this.foreImageList.length
    },
    foreImageListMaxPage() {
      return Math.ceil(this.foreImageListLength / 14.0)
    },
    mbList() {
      return [{}, {}]
    },
    // templateList5() {
    //   return this.imgConfigList.filter(o => o.imageType === 5)
    // },
    // templateList6() {
    //   return this.imgConfigList.filter(o => o.imageType === 6)
    // },
    // templateList7() {
    //   return this.imgConfigList.filter(o => o.imageType === 7)
    // },
    outLineList() {
      const filterArr = this.imgConfigList.filter(o => o.imageType === 2)
      return [{ typeId: null }, ...filterArr]
    },
    outLineImageListLength() {
      return this.outLineList.length
    },
    outLineImageListMaxPage() {
      return Math.ceil(this.outLineImageListLength / 14.0)
    },
    bodyList() {
      return this.imgConfigList.filter(o => o.imageType === 0)
    },
    eyeList() {
      return this.imgConfigList.filter(o => o.imageType === 1)
    }
  },
  watch: {
    showMobile(value) {
      if (value) {
        this._getMytplist()
      }
    },
    qrInfo: {
      handler() {
        if (_.isPlainObject(this.oldQrInfo)) {
          if (_.isEmpty(this.oldQrInfo.embedText) && _.isEmpty(this.qrInfo.embedText)) {
            const oldQrInfo = { ...this.oldQrInfo }
            const curOrInfo = { ...this.qrInfo }
            delete oldQrInfo.current
            delete oldQrInfo.embedText
            delete oldQrInfo.embedPosition
            delete oldQrInfo.embedText
            delete oldQrInfo.embedTextColor
            delete oldQrInfo.embedTextSize
            delete oldQrInfo.fontFamily
            delete curOrInfo.current
            delete curOrInfo.embedText
            delete curOrInfo.embedPosition
            delete curOrInfo.embedText
            delete curOrInfo.embedTextColor
            delete curOrInfo.embedTextSize
            delete curOrInfo.fontFamily
            if (JSON.stringify(oldQrInfo) === JSON.stringify(curOrInfo)) {
              this.oldQrInfo = { ...this.qrInfo }
              return false
            }
          }
        }
        this.oldQrInfo = { ...this.qrInfo } 
        if(this.templateId === 0 || !this.isFirst){
          console.log('new')
          this.beautifyQr(this.qrInfo)
        }
        this.isFirst = false
        
      },
      deep: true
    }
  },
  created() {
    const h = window.innerHeight
    const ph = 675
    if(h > ph){
      this.top = (h - ph)/2 + 'px'
    }else{
      this.top = '50px'
    }

    this.curContent = this.content
    this.oldQrInfo = { ...this.qrInfo }
    // this.qrParams.oldForeType = [{ foreColor: '#000000' }, { foreColor: '', foreColorTwo: '' }]
    if (this.showMobile) {
      if (_.isEmpty(this.tpList)) {
        this._getMytplist().then(() => {
          this.$nextTick(() => {
            if (_.isEmpty(this.tpList)) {
              this.mbNavItemIndex = 1
            } else {
              this.mbNavItemIndex = 0
            }
          })
        })
      } else {
        this.mbNavItemIndex = 0
      }
    }

    Promise.all([
      getImageConfig({ imageType: -1 }).then(data => {
      this.imgConfigList = data
      }),
      getTemplateConfig({ imageType: 5 }).then(data => {
        this.templateList5 = data
      }),
      getTemplateConfig({ imageType: 6 }).then(data => {
        this.templateList6 = data
      }),
      getTemplateConfig({ imageType: 7 }).then(data => {
        this.templateList7 = data
      }),
      getConfigColor({ typeId: 1 }).then(data => {
        if (_.isEmpty(data) === false) {
          let colors = data || []
          colors = colors.map(o => o.toLocaleUpperCase())
          const configColorAll = [...this.configColorAll, ...colors]
          this.configColorAll = configColorAll
        }
      }),
      getConfigColor({ typeId: 2 }).then(data => {
        if (_.isEmpty(data) === false) {
          let colors = data || []
          colors = colors.map(o => o.toLocaleUpperCase())
          const configColorAll = [...this.configColorAll, ...colors]
          this.configColorAll = configColorAll
        }
      })
    ]).then(() => {
      this.reviewStyle(this.$store.state.main.qrInfo)
    })

    
  },
  methods: {
    showPrompt(msg) {
      this.$message.error(msg)
    },
    showDialogWin() {
      this.$store.commit('user/SET_SHOW_DIALOGWIN', {
        showDialogWin: true,
        showWeChatLogin: true,
        showLoginWin: false,
        showRegWin: false
      })
      // getMytplist({}).then(data => {
      //   this.tpList = data
      // })
    },
    _getMytplist() {
      return getMytplist({
        'limit': 100,
        'page': 0
      }).then(data => {
        this.tpList = data.list || []
      })
    },
    submitContent() {
      if (!this.curContent) {
        this.$message({
          showClose: true,
          message: '请输入文字内容',
          type: 'error'
        })
      } else {
        this.content = this.curContent
        this.beautifyQr(this.qrInfo, true)
      }
    },
    // createQr(status) {
    //   if (!this.content) {
    //     this.$message({
    //       showClose: true,
    //       message: '请输入文字内容',
    //       type: 'error'
    //     })
    //     return false
    //   } else {
    //     createlog({
    //       'codeType': 0,
    //       'content': this.qrInfo.content,
    //       'height': this.qrInfo.size,
    //       'registerFrom': 1,
    //       'width': this.qrInfo.size
    //     })
    //   }
    // },
    reset() {
      this.$store.dispatch('main/resetQrInfo')
      this.$store.dispatch('main/resetQrParams')
      this.logoPercentage = 0
      this.bgColor = '#FFFFFF'
      this.foreImagePage = 1
      this.outLineImagePage = 1
      this.qrParams.foreType = 0

      document.querySelectorAll('.mb-item.active').forEach(element => {
        element.className = element.className.replace('active', '')
      })
    },
    handleSaveTemplate() {
      
      // if (!this.showMobile) {
      //   this.showDialogWin()
      // } else {
        const qrInfoTemp = _.cloneDeep(this.qrInfo)
        qrInfoTemp.content = this.content
        if(this.templateId){
          qrInfoTemp.templateId = this.templateId
        }else{
          qrInfoTemp.templateId = 0
        }
        this.$emit('finish', this.qrUrl)
        // saveTemplate(qrInfoTemp).then(() => {
        //   this.$message({ message: '二维码模板保存成功', type: 'success' })
        //   // this.tpList = [data, ...this.tpList]
        //   this._getMytplist()
        //   this.$emit('finish')
        // })
      // }
    },
    beautifyQr(nObj, needLog = false) {
      if (!this.curContent) {
        this.$message({
          showClose: true,
          message: '请输入文字内容',
          type: 'error'
        })
        return false
      } else {
        const qrInfoTemp = _.cloneDeep(nObj)
        qrInfoTemp.content = this.content
        qrInfoTemp.format = 1
        this.qrLoading = true
        this.submitContentDisabled = true
        styleCreate(qrInfoTemp).then(data => {
          if (data) {
            this.qrUrl = data
            this.editDialogVisible = false

            if (needLog) {
              createlog({
                'codeType': 0,
                'content': this.content,
                'height': this.qrInfo.size,
                'registerFrom': 1,
                'width': this.qrInfo.size
              })
            }
          }

          this.qrLoading = false
          this.submitContentDisabled = false
        }).catch(e => {
          this.qrLoading = false
          this.submitContentDisabled = false
          throw new Error(e)
        })
      }
    },
    getBgColor(colorItem) {
      const colors = colorItem.split('-')
      if (colors.length === 1) {
        return colors[0]
      } else if (colors.length === 2) {
        return `linear-gradient(-45deg,${colors[0]},${colors[1]});`
      }
    },
    downQr() {
      if (!this.qrUrl) {
        this.$message({
          showClose: true,
          message: '请先生成二维码',
          type: 'error'
        })
      } else {
        // window.open(this.qrUrl)
        const url = this.qrUrl // 获取图片地址
        downFile(url)
      }
    },
    handleOnChange(file) {
      // console.log(file)
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = event => {
        const src = event.target.result // base64
        const qrcode = new Decoder()
        qrcode
          .scan(src)
          .then(result => {
            this.$store.commit('main/SET_CONTENT', result.data)
            this.$nextTick(() => {
              this.beautifyQr(this.qrInfo, true)
              this.status = 'view'
            })
          })
          .catch(error => {
            this.errorDialogVisible = true
            console.error(error)
          })
      }
    },
    handleOnCancle() {
      this.$emit('on-cancle')
    },
    handleCancleErrorDialogVisible() {
      this.errorDialogVisible = false
    },
    reviewStyle(qrParams){
      const qrInfoTemp = { ...this.qrInfo, ...qrParams }
      delete qrInfoTemp.displayImage
      delete qrInfoTemp.templateId
      this.qrInfo = qrInfoTemp
      // 恢复模板的操作面板状态
      const tempQrParams = {
        logoId: -1,
        logoDisplayUrl: '',
        uploadLogoUrl: '',
        bgColor: qrInfoTemp.bgColor,
        foreColor: '',
        foreColorFirst: '',
        foreColorTwo: '',
        frameColorFlag: qrInfoTemp.frameColor ? 1 : 0,
        frameColor: qrInfoTemp.frameColor,
        embedTextList: qrInfoTemp.embedText.split(';'),
        foreType: qrInfoTemp.foreType,
        oldForeType: [{ foreColor: '#000000' }, { foreColor: '', foreColorTwo: '' }]
      }
      if (qrInfoTemp.foreType === 0) {
        tempQrParams.oldForeType[0].foreColor = qrInfoTemp.foreColor
        const hasSome = this.configColorAll.some(o => qrInfoTemp.foreColor === o)
        if (!hasSome) {
          tempQrParams.foreColor = qrInfoTemp.foreColor
        }
      } else if (qrInfoTemp.foreType === 1) {
        tempQrParams.oldForeType[1].foreColor = qrInfoTemp.foreColor
        tempQrParams.oldForeType[1].foreColorTwo = qrInfoTemp.foreColorTwo
        const hasSome = this.configColorAll.some(o => `${qrInfoTemp.foreColor}-${qrInfoTemp.foreColorTwo}` === o)
        if (!hasSome) {
          tempQrParams.foreColorFirst = qrInfoTemp.foreColor
          tempQrParams.foreColorTwo = qrInfoTemp.foreColorTwo
        }
      }
      if (qrInfoTemp.logoUrl) {
        const logo = this.logoList.find(o => o.imageSrc === qrInfoTemp.logoUrl || o.displayImageSrc === qrInfoTemp.logoUrl)
        if (logo) {
          tempQrParams.logoId = logo.typeId
          tempQrParams.logoDisplayUrl = logo.displayImageSrc
        } else {
          tempQrParams.uploadLogoUrl = qrInfoTemp.fileHost + qrInfoTemp.logoUrl
          tempQrParams.logoDisplayUrl = qrInfoTemp.fileHost + qrInfoTemp.logoUrl
        }
      }
      this.qrParams = tempQrParams
      if(this.qrParams.uploadLogoUrl){ // 自动选中logo
        this.handleChangleCustomLogo()
      }
      // 恢复模板的操作面板状态---end
    },
    handleChangeMb(qrParams, $event) {
      console.log(qrParams)
      this.reviewStyle(qrParams)
      // this.beautifyQr({ ...this.qrInfo })
      // this.beautifyQr({ ...this.qrInfo, ...qrInfoTemp })
      document.querySelectorAll('.mb-item.active').forEach(element => {
        element.className = element.className.replace('active', '')
      })
      if($event){
        $event.currentTarget.className += ' active'
      }
    },
    handleChangeDeleteMb(item) {
      delTemplateByid({ id: item.templateId }).then(() => {
        this.$message.success('模板已删除')
        _.remove(this.tpList, (o) => {
          return o.templateId === item.templateId
        })
        this.tpList = [...this.tpList]
        if (this.tpList.length === 0) {
          this.isEditMb = false
        }
      })
    },
    handleUploadLogo(response) {
      // console.log(response, file)
      // console.log(defaultSettings)
      this.qrParams.uploadLogoUrl = response.path
      this.handleChangleLogo(-2, this.qrParams.uploadLogoUrl, this.qrParams.uploadLogoUrl)
      this.logoPercentage = 0
    },
    handleProgressLogo(event) {
      // console.log(event, file, fileList)
      this.logoPercentage = parseInt(event.percent)
    },
    beforeLogoUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1

      if (!isLt1M) {
        this.$message.error('上传失败，logo图片不能超过 1MB')
      }
      return isLt1M
    },
    handleClearLogo() {
      this.qrParams.uploadLogoUrl = ''
      if (this.qrParams.logoId === -2) {
        this.qrInfo.logoUrl = ''
        this.qrParams.logoId = -1
      }
    },
    handleChangleLogo(logoId, url, displayUrl) {
      this.qrParams.logoId = logoId
      this.qrInfo.logoUrl = url
      this.qrParams.logoDisplayUrl = displayUrl
    },
    handleChangleCustomLogo() {
      if (this.qrParams.uploadLogoUrl) {
        this.qrInfo.logoUrl = this.qrParams.uploadLogoUrl
        this.qrParams.logoDisplayUrl = this.qrParams.uploadLogoUrl
        this.qrParams.logoId = -2
      }
    },
    handleChangeForeType(lable) {
      // if (lable !== 2) {
      //   this.qrParams.oldForeType[lable ^ 1] = { foreColor: this.qrInfo.foreColor, foreColorTwo: this.qrInfo.foreColorTwo }
      // }
      if (lable === 0) {
        if (this.qrInfo.foreColor === '#000000' && this.qrParams.foreColor === '') {
          return
        }
        this.qrInfo.foreColor = this.qrParams.oldForeType[0].foreColor
        this.qrInfo.foreType = lable
      } else if (lable === 1) {
        if (!this.qrParams.foreColorFirst && !this.qrParams.foreColorTwo && !this.qrInfo.foreColorTwo) {
          return
        }
        this.qrInfo.foreColor = this.qrParams.oldForeType[1].foreColor
        this.qrInfo.foreColorTwo = this.qrParams.oldForeType[1].foreColorTwo
        this.qrInfo.foreType = lable
      } else {
        if (this.qrInfo.foreColorImage === '') {
          return
        }
        this.qrInfo.foreType = lable
      }
    },
    handleChangeForeColor(colorItem) {
      const colors = colorItem.split('-')
      this.qrInfo.foreColor = colors[0]
      if (colors.length === 2) {
        this.qrInfo.foreType = 1
        this.qrInfo.foreColorTwo = colors[1]
      } else {
        this.qrInfo.foreType = 0
        this.qrInfo.foreColorTwo = ''
      }
      this.qrParams.oldForeType[ this.qrInfo.foreType] = { foreColor: this.qrInfo.foreColor, foreColorTwo: this.qrInfo.foreColorTwo }
    },
    handleChangeCustomForeColor(value) {
      this.qrParams.foreColor = value
      this.qrInfo.foreColor = value
      this.qrInfo.foreType = 0
      this.qrParams.oldForeType[ 0] = { foreColor: value, foreColorTwo: '' }
    },
    handleChangeCustomForeColor1(value, type = 'foreColor') {
      this.qrInfo.foreType = 1
      if (type === 'foreColor') {
        this.qrParams.foreColorFirst = value
        this.qrInfo.foreColor = value
        this.qrInfo.foreColorTwo = this.qrParams.foreColorTwo
      } else {
        this.qrParams.foreColorTwo = value
        this.qrInfo.foreColorTwo = value
        this.qrInfo.foreColor = this.qrParams.foreColorFirst
      }
      this.qrParams.oldForeType[ this.qrInfo.foreType] = { foreColor: this.qrInfo.foreColor, foreColorTwo: this.qrInfo.foreColorTwo }
    },
    isForeColorActive(colorItem) {
      if (colorItem.length === 7) {
        return this.qrInfo.foreColor === colorItem
      } else {
        return (
          this.qrInfo.foreColor + '-' + this.qrInfo.foreColorTwo === colorItem
        )
      }
    },
    handleChangeForeImage(foreItem) {
      this.qrInfo.foreColorImage = foreItem.imageSrc
      this.qrInfo.foreType = 2
    },
    goForePage($event, count) {
      // console.log($event)
      if (
        $event.target.getAttribute('class').indexOf('disabled') > -1 ||
        $event.target.parentElement.getAttribute('class').indexOf('disabled') >
          -1
      ) {
        return
      }
      this.foreImagePage = this.foreImagePage + count
    },
    handleEmbedTextBlur() {
      this.qrInfo.embedText = this.qrParams.embedTextList
        .filter(o => o.length > 0)
        .join(';')
    },
    handleDeleteEmbedText(index) {
      _.remove(this.qrParams.embedTextList, (o, i) => {
        return i === index
      })
      this.qrParams.embedTextList = [...this.qrParams.embedTextList]
      this.handleEmbedTextBlur()
    },
    handleChangeOutLineImage(item) {
      this.qrInfo.frameId = item.typeId
      // this.qrParams.outLineImageTypeId = item.typeId
      // 选择外框后为了整体的美观性，将二维码边距调整为0个色块
      this.qrInfo.margin = 0
    },
    goOutLinePage($event, count) {
      // console.log($event)
      if (
        $event.target.getAttribute('class').indexOf('disabled') > -1 ||
        $event.target.parentElement.getAttribute('class').indexOf('disabled') >
          -1
      ) {
        return
      }
      this.outLineImagePage = this.outLineImagePage + count
    },
    handleChangeFrameColor(value) {
      if (value) {
        this.qrInfo.frameColor = value
        this.qrParams.frameColorFlag = true
      }
    },
    handleChangeFrameColorFlag(value) {
      if (value) {
        this.qrInfo.frameColor = this.qrParams.frameColor
      } else {
        this.qrInfo.frameColor = ''
      }
    }
  }
}
</script>
<style lang="scss">
.el-color-picker{
  cursor: pointer;
  height: 40px;
  &::before{
   
    content: "";
    background-size: contain;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .el-icon-arrow-down:before {
    content: "";
}
  .el-color-picker__color{
    background-image: url('./images/color.png');
    background-position: -8px -8px;
    background-size: 56px;
    border: 0;
  }
  .el-color-picker__trigger{
    // height: 42px;
    // width: 42px;
    padding: 0px;
    border:0;
  }
  .el-color-picker__empty {
    display: none;
  }
  .el-color-picker__color-inner {
    position: absolute;
    // left: 1.5px;
    // top: 1.5px;
    // right: 1.5px;
    // bottom: 1.5px;
    border-radius: 3px;
  }
}
@import './qrDialog.scss';
</style>
