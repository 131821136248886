<template>
  <el-date-picker
    v-model="date"
    size="small"
    type="daterange"
    range-separator="至"
    @change="change"
    value-format="yyyy-MM-dd"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :clearable="false"
    :picker-options="pickerOptions"
  >
  </el-date-picker>
</template>

<script>
import moment from 'moment'
export default {
  name: "datePicker",
  props: {
    max: {
      type: Number,
      default: 730
    }
  },
  data(){
    return {
      date: []
    }
  },
  computed: {
    pickerOptions(){
      let self = this
      return {
        onPick: ({ maxDate, minDate }) => {        
         this.minDate = minDate
         this.maxDate = maxDate
        },
        disabledDate: (time) => { //查询时间跨度为720天
          if(this.minDate){
            let range = self.max * 24 * 3600 * 1000
            return time.getTime() > Date.now() || time.getTime() > (this.minDate.getTime() + range) || time.getTime() < (this.minDate.getTime() - range)
          }
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        },
        {
          text: '最近半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  mounted(){
    let month = moment().format("MM")
    let date = []
    if(parseInt(month) === 1){
      let lastYear = moment().subtract(1, 'years')
      let Y = lastYear.format('YYYY')
      date = [Y + '-01' + "-01", Y + '-12' + "-31"]
    }else{
      let month = moment().subtract(1, 'months')
      let end = month.daysInMonth()
      let YM = month.format('YYYY-MM')
      let Y = month.format('YYYY')
      date = [Y + "-01-01", YM + "-" + end]
    }
    this.date = date
    this.$emit("change", date)
  },
  methods: {
    change(val){
      this.$emit("change", val)
    }
  }
}
</script>

<style lang="scss">

</style>
