<template>
  <div class="qr-view-wrapper">
    <div class="qr-view-content" :style="`background:${qrUrl?'transparent':'#fff'}`">
      <img v-if="qrUrl" :src="qrUrl" style="width:310px;display: block;">
      <div v-else class="default-content">
        <img src="./images/qr-default.png" alt="此处生成二维码">
        <p>此处生成二维码</p>
      </div>
    </div>
    <div v-if="loading === true" style="position:absolute; width:100%; height:100%; top:0; left:0; background:#ffffff80" class="qr-view-content">
      <div class="qr-loading">
        <div class="three1" />
        <div class="three2" />
        <div class="three3" />
        <div class="three4" />
      </div>
    </div>
    <div v-if="content.length > 150 && qrUrl" style="text-align:center;">
      <p style="font-size:15px;color:#F8545B; margin-bottom: 7px;">
        内容超150字，手机和扫码枪不易扫描<a target="_blank" href="https://www.hlcode.cn/help/knowledge.html#%E4%BA%8C%E7%BB%B4%E7%A0%81%E5%9B%BE%E6%A1%88%E4%B8%BA%E4%BB%80%E4%B9%88%E5%A4%8D%E6%9D%82"><span class="question">?</span></a>
      </p>
      <p style="font-size:14px;color:#999999; margin-bottom: 0;">
        为避免损失，请在真实场景中扫码确认
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    qrUrl: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    content: {
      get() {
        return this.$store.state.main.content
      }
    }
  },

  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
@keyframes three {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}
.qr-loading {
  width: 150px;
  padding-top: 50%;
  padding-bottom: 15px;
  margin: 0 auto;
  text-align: center;
  & > div {
    width: 10px;
    height: 10px;
    margin-left: 8px;
    border-radius: 100%;
    display: inline-block;
    background-color: #2eb362;
    -webkit-animation: three 1.4s infinite ease-in-out;
    animation: three 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  & .three1 {
    -webkit-animation-delay: -0.45s;
    animation-delay: -0.45s;
  }
  & .three2 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  & .three3 {
    -webkit-animation-delay: -0.15s;
    animation-delay: -0.15s;
  }
}

.qr-view-wrapper {
      position: relative;
  .qr-view-content {

    width: 310px;
    min-height: 310px;
    box-shadow: 0px 3px 5px 0px rgba(0, 19, 11, 0.08);
    // padding: 10px;
    background: transparent;
    position: relative;
    text-align: center;
    .default-content {
      position: relative;
      top: 103px;
      text-align: center;
      font-size: 14px;

      img {
        width: 75px;
        background: transparent;
      }
      p {
        margin-top: 10px;
        color: rgba(170, 170, 170, 1);
      }
    }
  }
  .question {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgb(248, 84, 91);
    margin-left: 4px;
    vertical-align: bottom;
    font-size: 13px;
  }
}
</style>
