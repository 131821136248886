import request from './request'
const BATH_PATH = process.env.VUE_APP_BASE_QRUSER_API
const BATH_FILE_PATH = process.env.VUE_APP_BASE_FILE_API
/**
 * 获取图片配置
 * @param {} data
 */
export function getImageConfig(data) {
  return request({
    url: '/qruser/config/get_image_config',
    method: 'post',
    data,
    baseURL: BATH_PATH
  })
}

/**
  * 获取美化颜色
  * @param {*} data
  */
export function getConfigColor(data) {
  return request({
    url: '/qruser/config/get_config_color',
    method: 'post',
    data,
    baseURL: BATH_PATH
  })
}

/**
 * 获取模板列表
 * @param {} data
 */
export function getTemplateConfig(data) {
  return request({
    url: '/qruser/config/get_template_config',
    method: 'post',
    data,
    baseURL: BATH_PATH
  })
}

/**
 * 获取模板列表
 * @param {} data
 */
export function getMytplist(data) {
  return request({
    url: '/qruser/user/template/get_mytplist',
    method: 'post',
    data,
    baseURL: BATH_PATH
  })
}

// /**
//  * 保存模板
//  * @param {} data
//  */
// export function saveTemplate(data) {
//   return request({
//     url: '/qruser/user/template/save_template',
//     method: 'post',
//     data,
//     baseURL: BATH_PATH
//   })
// }

/**
 * 保存模板
 * @param {} data
 */
export function delTemplateByid(data) {
  return request({
    url: '/qruser/user/template/del_template_byid',
    method: 'post',
    data,
    baseURL: BATH_PATH
  })
}

/**
 * 二维码流水
 * @param {} data
 */
export function createlog(data) {
  return request({
    url: '/qruser/code/createlog',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 创建分组
 * @param {} data
 */
 export function addGroup(data, config) {
  return request({
    url: '/qruser/console/group/add',
    method: 'post',
    data: data,
    ...config,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 重命名分组名称
 * @param {} data
 */
export function renameGroup(data) {
  return request({
    url: '/qruser/console/group/rename',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 删除分组
 * @param {} data
 */
export function deleteGroup(data) {
  return request({
    url: '/qruser/console/group/del',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 调整分组排序
 * @param {} data
 */
export function changeOrder(data) {
  return request({
    url: '/qruser/console/group/changeorder',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 获取分组列表
 * @param {} data
 */
export function getGroupList(data) {
  return request({
    url: '/qruser/console/group/list',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 修改微信活码名称
 * @param {} data
 */
 export function changeGroupCodeName(data) {
  return request({
    url: '/qruser/console/group/rename_groupmain',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 添加群活码
 * @param {} data
 */
export function addGroupCode(data, config) {
  return request({
    url: '/qruser/console/groupsub/add',
    method: 'post',
    data: data,
    ...config,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 修改群活码
 * @param {} data
 */
export function editGroupCode(data) {
  return request({
    url: '/qruser/console/groupsub/edit',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 获取二维码列表
 * @param {} data
 */
export function getGroupCodeList(data) {
  return request({
    url: '/qruser/console/groupsub/list',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 批量删除二维码
 * @param {} data
 */
export function deleteGroupCode(data) {
  return request({
    url: '/qruser/console/groupsub/batch_del',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 批量修改二维码
 * @param {} data
 */
export function changeLimit(data) {
  return request({
    url: '/qruser/console/groupsub/change_limit',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 切换方式
 * @param {} data
 */
export function setSwitchType(data) {
  return request({
    url: '/qruser/console/group/set_change',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

/**
 * 群活码 获取当前展示的二维码
 * @param {} data
 */
export function getGroupImg(data) {
  return request({
    url: '/qruser/qroce/info/get_groupimg',
    method: 'post',
    data: data,
    baseURL: BATH_PATH
  })
}

export const upload = (data, config) => {
  return request({
    url: '/file/upload',
    method: 'post',
    data,
    ...config,
    baseURL: BATH_FILE_PATH
  })
}