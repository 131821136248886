<template>
  <el-select style="margin-left: 12px" size="small" v-model="channel" @change="change" >
    <el-option value=""  label="全部" ></el-option>
    <el-option v-for="val in list" :value="val.spreadId" :label="val.title" :key="val.id" ></el-option>
  </el-select>
</template>

<script>
import {getSpreadList} from '@/api'
export default {
  name: "channelSelect",
  data(){
    return {
      // show: false,
      channel: "",
      list: []
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    change(val){
      this.$emit("change", val)
    },
    getList(){
      getSpreadList().then(resp => {
        this.list = resp
        if(this.list.length === 1){
          this.$emit("change", this.list[0].spreadId)
          // this.show = false
        }else{
          this.$emit("change", "")
          // this.show = true
        }
      })
    }
  }
}
</script>
<style lang="scss">

</style>
