<template>
  <el-dialog
    class="qr-error-dailog-wrapper"
    :visible.sync="visible"
    width="550px"
    :center="true"
    :lock-scroll="true"
    :show-close="true"
    :append-to-body="true"
    :before-close="handleOnCancle"
  >
    <i class="iconfont">&#xe60c;</i>
    <p style="font-size:24px;color:#333333;font-weight:400;    margin-top: 15px; margin-bottom: 20px;">解码失败</p>
    <p style="font-size:15px;color:#999999;font-weight:300;">
      请检查二维码图片后重新上传
    </p>
    <el-button type="primary" style="width:110px;margin-top:10px;" size="small" @click="handleOnCancle">确定</el-button>
  </el-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // visible: false
    }
  },
  methods: {
    handleOnCancle() {
      this.$emit('on-cancle')
    }
  }
}
</script>
<style lang="scss">
.qr-error-dailog-wrapper {
  .el-dialog__body{
        text-align: center;
  }
  .iconfont {
    color: #f8545b;
    border-radius: 50%;
    font-size: 45px;
  }
}
</style>
