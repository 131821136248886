<template>
  <div class="component-line-chart">
    <v-chart :options="chartData" />
  </div>
</template>
<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
export default {  
  name: 'lineChart',
  components: {
    'v-chart': ECharts
  },
  props: {
    options: {
      type: Object,
      default(){
        return {

        }
      }
    }
  },
  data(){
    return {
      
    }
  },
  methods:{
    deepCopy(obj){
      return JSON.parse(JSON.stringify(obj))
    }
  },
  computed: {
    chartData(){
      return {
        // animationDuration: 500,
        notMerge: false,
        color: ['#2EB362','#6AB4F4'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#bfbfbf',
              type: 'dashed'
            }
          }
        },
        legend: {
          right: 30,
          top: (this.options.legend && this.options.legend.top) ||30,
          itemGap: 20,
          itemWidth: 8,
          itemHeight: 12,
          textStyle: {
            fontSize: 14,
            fontWeight: 600,
            color: '#333'
          },
          symbolRotate: '45',
          data: (this.options.leagend && this.options.leagend.data) || ['扫码次数', '扫码人数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: (this.options.xAxis && this.options.xAxis.data) || [1,2,3,4,5,6,7],
          axisLabel: {
            color: '#333',
          },
          axisLine: {
            lineStyle: {
              color: '#f4f4f4'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4'
            }
          },
        },
        yAxis: {
          type: 'value',
          nameTextStyle:{
            color: '#333'
          },
          axisLabel: {
            color: '#333',
          },
          axisLine: {
            lineStyle: {
              color: '#f4f4f4'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#f4f4f4'
            }
          }
        },
        series: this.options.series || [
          {
            name: '扫码次数',
            symbol: 'circle',
            symbolSize: 6,
            type: 'line',
            // stack: '总量',
            data: [0,0,0,0,0,0,0]
          },
          {
            name: '扫码人数',
            type: 'line',
            symbolSize: 8,
            symbol: 'diamond',
            // stack: '总量',
            data: []
          }
        ]
      }
    }
  }
}
</script>
<style lang="scss">
.component-line-chart{
  height: 100%;
  .echarts{
    width: 100%;
  }
}
</style>