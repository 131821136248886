<template>
  <div class="page-promotion">
    <div class="search-box">
      <date-picker @change="getDate"></date-picker>
      <el-button style="margin-left: 12px" size="small" type="primary" @click="search" >查询</el-button>
      <el-button style="margin-left: 12px" size="small" type="primary" @click="apply" >申请结算</el-button>
    </div>
    <div class="table-box">
      <table>
        <thead>
          <tr>
            <th>月份</th>
            <th>佣金</th>
            <th>状态</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="val in list" :key="val.item">
            <td>{{val.month}}</td>
            <td>¥{{val.commissionAmount}}</td>
            <td>{{statusMap[val.status]}}</td>
          </tr>
        </tbody>
      </table>
      <div class="no-data" v-if="list.length === 0" >
        暂无数据
      </div>
    </div>

    <el-dialog
      :visible.sync="showDialog"
      width="500px"
      custom-class="commission-dialog"
      :close-on-click-modal="false"
    >
      <div class="commission-dialog-box">
        <dl>
          <dd>
            <div>
              <svg-icon v-if="isApplySuccess" icon-class="form-radio"></svg-icon>
              <svg-icon v-else style="color:red" icon-class="icon-error"></svg-icon>
            </div>
          </dd>
          <dt>
            <h3>{{isApplySuccess ? '结算申请提交成功' : "结算失败"}}</h3>
            <p>{{applyMsg}}</p>
            <el-button type="primary" size="small" @click="confirm" >确定</el-button>
          </dt>
        </dl>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {getCommissionList, applyCommission} from '@/api'
import datePicker from '@/components/datePicker2'
export default {
  name: "commission",
  data(){
    return {
      showDialog: false,
      date: [],
      channel: "",
      isOnly: false,
      list: [],
      statusMap: ['待申请结算','处理中','已完成'],
      isApplySuccess: false,
      applyMsg: ""
    }
  },
  components: {
    datePicker
  },
  mounted(){
    this.getList()
  },
  methods: {
    confirm(){
      if(this.isApplySuccess){
        this.getList()
      }
      this.showDialog = false
    },
    apply(){
      applyCommission({
        endDate: this.date[1] + " 23:59:59",
        startDate: this.date[0] + " 00:00:00"
      }, {errorCode: [99, 200]}).then(resp => {
        if(resp.code === 0){
          this.isApplySuccess = true
          this.applyMsg = resp.data
        }else{
          this.isApplySuccess = false
          this.applyMsg = resp.msg
        }
        this.showDialog = true
      })
    },
    search(){
      this.getList()
    },
    getList(){
      getCommissionList({
        endDate: this.date[1] + " 23:59:59",
        startDate: this.date[0] + " 00:00:00"
      }).then(resp => {
        this.isOnly = this.channel ? true : false
        this.list = resp
      })
    },
    getDate(val){
      this.date = val
    },
  }
}
</script>

<style lang="scss">
.commission-dialog{
  .el-dialog__header{
    display: none;
  }
  .commission-dialog-box{
    padding: 40px 16px;
    text-align: center;
    dd{
      font-weight: normal;
      font-size: 50px;
      color: $primary; 
    }
    dt{
      margin-top: 18px;
    }
    h3{
      font-size: 20px;
      font-weight: 500;
      color: #333333;
    }
    p{
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }
    .el-button{
      margin-top: 28px;
      width: 100px;
    }
  }
}
.page-promotion{
  .search-box{

  }
  .table-box{
    margin-top: 24px;
    table{
      width: 100%;
      @include tableBorder;
      border: 1px solid #e7e7e7;
      td,th{
        height: 49px;
        text-align: center;
      }
      td{
        background: #fff;
        border-top: 1px solid #e7e7e7;
      }
      th{
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        opacity: 0.89;
        background: #f7f7f7;
      }
    }
    .no-data{
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-top: none;
    }
  }
}
</style>