<template>
  <div class="page-promotion">
    <div class="search-box">
      <date-picker :type="2" @change="getDate"></date-picker>
      <channel-select @change="getChannel"></channel-select>
      <el-button style="margin-left: 12px" size="small" type="primary" @click="search" >查询</el-button>
    </div>
    <div class="table-box">
      <table>
        <thead>
          <tr>
            <th style="width: 350px;" v-if="!isOnly">推广名称</th>
            <th>{{isOnly ? "日期" : "推广ID"}}</th>
            <th>推广用户数</th>
            <th>注册账号</th>
            <th>账号注册率</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="val in list" :key="val.item">
            <td v-if="!isOnly">{{val.channelName}}</td>
            <td>{{val.item}}</td>
            <td>{{val.userCount}}</td>
            <td>{{val.registerCount}}</td>
            <td>{{val.registerRate}}</td>
          </tr>
        </tbody>
      </table>
      <div class="no-data" v-if="list.length === 0" >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import {getChannelStatistics} from '@/api'
import datePicker from '@/components/datePicker'
import channelSelect from '@/components/channelSelect'
export default {
  name: "promotion",
  data(){
    return {
      date: [],
      channel: "",
      isOnly: false,
      list: []
    }
  },
  components: {
    datePicker, channelSelect
  },
  mounted(){
    
  },
  methods: {
    search(){
      this.getList()
    },
    getList(){
      getChannelStatistics({
        channelId: this.channel,
        endDate: this.date[1] + " 23:59:59",
        startDate: this.date[0] + " 00:00:00"
      }).then(resp => {
        this.isOnly = this.channel ? true : false
        this.list = resp
      })
    },
    getDate(val){
      this.date = val
    },
    getChannel(val){
      this.channel = val
      this.getList()
    }
  }
}
</script>

<style lang="scss">
.page-promotion{
  .search-box{

  }
  .table-box{
    margin-top: 24px;
    table{
      width: 100%;
      @include tableBorder;
      border: 1px solid #e7e7e7;
      td,th{
        height: 49px;
        text-align: center;
      }
      td{
        background: #fff;
        border-top: 1px solid #e7e7e7;
      }
      th{
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        opacity: 0.89;
        background: #f7f7f7;
      }
    }
    .no-data{
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-top: none;
    }
  }
}
</style>