<template>
  <el-container class="page-apply">
    <el-header height="75px" >
      <common-header/>
    </el-header>
    <el-container class="apply-container">
      <!-- 未提交或者审核驳回 -->
      <div class="apply-box" v-if="!loading">
        <div v-if="status === -1 || status === 2">
          <h3 class="title" v-if="status === -1">互联二维码合伙人申请</h3>
          <p class="errorTip" v-if="status === 2">资料驳回：{{info.rejectReason}}</p>
          <div class="form-box">
            <el-form label-position="left" :model="form" :rules="rules" ref="form" :label-width="form.partnerType?'150px':'110px'" class="demo-ruleForm">
              <el-form-item label="合伙人类别" prop="partnerType">
                <el-radio-group v-model="form.partnerType">
                  <el-radio  :label="0">个人</el-radio>
                  <el-radio  :label="1">公司</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="name" prop="name">
                <el-input v-model="form.name" :placeholder="name" ></el-input>
              </el-form-item>
              <el-form-item :label="card" prop="cardNo">
                <el-input v-model="form.cardNo" :placeholder="card" ></el-input>
              </el-form-item>
              <el-form-item :label="img" >
                <img class="avatar" v-if="form.cardNoImg" :src="form.cardNoImg" alt="" @click="openFile" >
                <div v-if="!form.cardNoImg" class="add-img" @click="openFile">
                  <span class="el-icon-plus"></span>
                  <p>添加图片</p>
                </div>
                <input style="display: none" ref="file" accept="image/*" type="file" @change="upload">
                <p class="tip">支持jpg、png、jpeg、bmp格式图片，最大2M</p>
              </el-form-item>
              <el-form-item label="" >
                <el-button type="primary" size="small" style="width: 110px;height: 36px;" @click="add" >提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 未审核 -->
        <div class="info" v-if="status === 0" >
          <p class="waitTip">
            资料审核中，请耐心等待，1-5个工作日完成审核。
          </p>
          <ul>
            <li>
              <p class="name">合伙人类别：</p>
              <p class="content">{{['个人','企业'][info.partnerType]}}</p>
            </li>
            <li>
              <p class="name">{{name}}：</p>
              <p class="content">{{info.partnerName}}</p>
            </li>
            <li>
              <p class="name">{{card}}：</p>
              <p class="content">{{info.cardNo}}</p>
            </li>
            <li>
              <p class="name">{{img}}：</p>
              <p class="content">
                <el-image class="avatar" :src="info.cardNoSrc" :preview-src-list="[info.cardNoSrc]"></el-image>
              </p>
            </li>
          </ul>
        </div>
        <!-- 审核通过填写银行卡信息 -->
        <div v-if="status === 1">
          <h3 class="title" >审核通过，请填写银行卡信息</h3>
          <p class="titleTip">请填写用于收取佣金的银行卡信息以便佣金发放，收款人姓名需与申请人一致</p>
          <div class="form-box" style="margin-top: 50px;">
            <el-form label-position="left" :model="bankForm" :rules="bankRules" ref="bankForm" label-width="110px" class="demo-ruleForm">
              <el-form-item :label="bankUsername+'：'" >
                {{info.partnerName}}
              </el-form-item>
              <el-form-item label="开户银行：" prop="bankName">
                <el-input v-model="bankForm.bankName" placeholder="开户银行" ></el-input>
              </el-form-item>
              <el-form-item label="银行账号：" prop="bankCardNo">
                <el-input v-model="bankForm.bankCardNo" placeholder="银行账号" ></el-input>
              </el-form-item>
              <el-form-item label=" " >
                <div style="margin-top: -10px;">
                  <el-checkbox v-model="agree">
                    <p class="agree">
                      阅读并同意<span @click="toPdf">《互联二维码合伙人合作协议》</span>（包含佣金结算规则）
                    </p>
                  </el-checkbox>
                </div>
                <el-button type="primary" size="small" style="margin-top: 20px; width: 110px;height: 36px;" @click="addBank" >提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        
      </div>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
import {applyPartner, getPartner, upload, reApplyPartner, addBank} from "@/api"
import commonHeader from '@/components/header' 
export default {
  name: 'Apply',
  data(){
    return {
      loading: true,
      agree: false,
      status: -1,
      form:{
        name: "",
        cardNo: "",
        cardNoImg: "",
        partnerType: 0
      },
      info: {

      },
      bankForm: {
        bankName: "",
        bankCardNo: ""
      },
      bankRules:{
        bankName: [
          { required: true, message: '请输入银行名称', trigger: 'blur' }
        ],
        bankCardNo: [
          { required: true, message: '请输入银行账号', trigger: 'blur' }
        ],
      }
    }
  },
  computed:{
    name(){
      return ['姓名','公司名称'][this.form.partnerType]
    },
    bankUsername(){
      return ['收款人姓名','公司名称'][this.form.partnerType]
    },
    card(){
      return ['身份证号','统一社会信用代码'][this.form.partnerType]
    },
    img(){
      return ['身份证照片','营业执照照片'][this.form.partnerType]
    },
    rules(){
      let nameMax = [10, 50][this.form.partnerType]
      let cardMax = [18, 25][this.form.partnerType]
      return {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { max: nameMax, message: `最多输入${nameMax}个字符`, trigger: 'blur' }
        ],
        cardNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { max: cardMax, message: `最多输入${cardMax}个字符`, trigger: 'blur' }
        ],
        cardNoImg: [
          { required: true, message: '请上传图片', trigger: 'blur' }
        ],
      }
    }
  },
  components: {
    commonHeader
  },
  mounted(){
    this.getData()
  },
  methods:{
    toPdf(){
      window.open("https://oss.hlcode.cn/open/partner/%E4%BA%92%E8%81%94%E4%BA%8C%E7%BB%B4%E7%A0%81%E5%90%88%E4%BC%99%E4%BA%BA%E5%90%88%E4%BD%9C%E5%8D%8F%E8%AE%AE.pdf")
    },
    addBank(){
      this.$refs["bankForm"].validate((valid) => {
        if (valid) {
          if(!this.agree){
            this.$message.error("请先同意合作协议")
            return
          }
          addBank(this.bankForm).then(() => {
            // this.$message.success("添加银行卡信息成功")
            this.$router.push('/info')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
    },
    openFile(){
      this.$refs.file.click()
    },
    upload(e){
      if(e.target.files[0].size > 2048 * 1024){
        this.$message.error("照片不能超过2MB")
        e.target.value = ""
        return
      }
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('type', 0)
      upload(formData).then(resp => {
        this.form.cardNoImg = resp.fileUrl
        e.target.value = ""
      })
    },
    getData(){
      return getPartner().then(resp => {
        if(resp){
          this.info = resp
          for(let key in this.form){
            this.form[key] = resp[key]
          }
          this.form.name = resp.partnerName
          this.form.cardNoImg = resp.cardNoSrc
          this.status = resp.status

          if(this.status === 1 && resp.bankCardNo){
            this.$router.push("/data")
          }
        }else{
          this.status = -1
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    add(){
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if(this.form.cardNoImg === ""){
            this.$message.error("请上传照片")
            return
          }
          if(this.status === 2){
            reApplyPartner(this.form).then(() => {
              this.getData().then(() => {
                this.status = 0
              })
              
            })
          }else{
            applyPartner(this.form).then(() => {
              this.getData().then(() => {
                this.status = 0
              })
            })
          }
          
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss">

.page-apply{
  height: 100vh;
  font-size: 14px;
  color: #333;
  background: #eef1f3;
  .el-header{
    position: relative;
    height: 75px;
    box-shadow: 0px 0px 4px 0px rgba(0, 19, 11, 0.18);
    z-index: 9;
    background: #fff;
  }
  .apply-container{
    padding-bottom: 20px;
    height: calc(100vh - 75px);
    overflow-y: auto;
    @include scrollBar;
  }
  .apply-box{
    margin: 24px auto auto auto;
    width: 800px;
    padding: 60px 120px;
    background: #fff;
    .el-form-item__label{
      &::before{
        display: none;
      }
    }
  }
  .title{
    font-size: 36px;
    font-weight: 600;
    color: #333333;
    text-align: center;
  }
  .titleTip{
    margin-top: 16px;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
  .errorTip{
    // height: 46px;
    padding: 13px 14px;
    background: rgba(247, 54, 63, 0.06);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #F7363F;
    line-height: 20px;
    text-align: left;
  }
  .form-box{
    margin-top: 50px;
    .el-form-item__label{
      font-size: 15px;
      font-weight: 500;
      color: #333333;
    }
    .add-img{
      padding-top: 32px;
      width: 110px;
      height: 110px;
      background: #F3F4F5;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      span{
        font-size: 28px;
        color: #aeaeae;
        // font-weight: bold;
      }
      p{
        line-height: 13px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
      }
    }
    .tip{
      color: #999;
      font-size: 13px;
    }
  }

  .avatar{
    width: 110px;
    height: 110px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
  }

  .info{
    .waitTip{
      // width: 560px;
      height: 46px;
      background: rgba(37, 120, 227, 0.08);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #3575BD;
      line-height: 46px;
      text-align: center;
    }
    ul{
      margin-top: 60px;
      li{
        margin-top: 30px;
        p{
          display: inline-block;
          vertical-align: top;
        }
        .name{
          width: 165px;
          color: #666;
        }
        .content{
          color: #333;
          font-weight: 500;
          width: 394px;
          @include textOverflow;
        }
      }
    }
  }

  .agree{
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    span{
      color: $link;
    }
  }

}

</style>
