
export const SEO = [
  // 文本
  {
    key: "",
    title: '互联二维码，可以将视频图片生成二维码，扫码即可查看',
    description: '互联二维码生成器，可以将视频图片文档生成二维码，扫码即可查看，二维码不变随时修改内容。图片、音视频、文档简单排版即可生成精美页面，适用于企业宣传、产品介绍、营销推广等。'
  },
  // 音视频
  {
    key: "media",
    title: '视频二维码生成器，将音视频生成二维码，扫码在线播放 - 互联二维码',
    description: '视频二维码生成器，可以把音频、视频上传生成二维码，扫一扫即可在线播放，还能随时修改内容。支持添加图片、文档简单排版即可生成精美页面，适用于企业宣传、产品介绍、教育培训等。'
  },
  // 文件
  {
    key: "files",
    title: '文档二维码生成器，把Word、Excel、PDF生成二维码，扫码即可查看 - 互联二维码',
    description: '文档二维码生成器，可以把Word、Excel、PDF等上传生成二维码，扫码即可在线查看，还能随时修改内容。支持添加图片、视频简单排版即可生成精美页面，适用于企业宣传、产品介绍、教育培训等。'
  },
  // 图片
  {
    key: "img",
    title: '图片二维码生成器，把图片生成二维码图片多种样式展现，扫码即可观看 - 互联二维码',
    description: '图片二维码生成器，可以把图片转换成二维码，还支持添加文件、视频简单排版即可生成精美页面，扫一扫就能查看，二维码不变可随时修改内容，适用于企业宣传、产品介绍、教育培训等。'
  },
  // 微信活码
  {
    key: "group",
    title: '微信活码系统，突破微信扫码限制快速引流 - 互联二维码',
    description: '互联二维码微信活码系统，解决超200人无法扫码入群和群码7天失效难题，突破个人号每天加好友限制。防止用户重复进群、自定义分组、渠道码，精细化运营提高效率。'
  },
  // 模板库
  {
    key: "template",
    title: '产品模板库，简单替换内容即可生成精美页面 - 互联二维码',
    description: '精美产品模板库，提供企业宣传、产品介绍、旅游行程、教育培训、有声读物、产品说明书、产品标签、博物馆展品讲解等产品模板，简单替换即可生成精美页面，快速应用。'
  },
  // 网址
  {
    key: "url",
    title: '网址二维码生成器，随时修改跳转链接 - 互联二维码',
    description: '网址二维码生成器，可快速制作网址跳转二维码，支持无中间页直接跳转、随时修改跳转链接，后台可查看扫描统计数据。'
  },
  // 网址
  {
    key: "polymer/1",
    title: '互联二维码，简单好用的二维码系统',
    description: '互联二维码生成器，可以将视频图片文档生成二维码，扫码即可查看，二维码不变随时修改内容。图片、音视频、文档简单排版即可生成精美页面，适用于企业宣传、产品介绍、营销推广等。'
  }
]


