<template>
  <div class="page-info">
    <el-row :gutter="24" >
      <el-col :span="12" >
        <div class="card">
          <ul v-show="info.partnerName">
            <li>
              <p class="name">合伙人类别</p>
              <p class="con">{{["个人","企业"][this.info.partnerType]}}</p>
            </li>
            <li>
              <p class="name">{{name}}</p>
              <p class="con">{{info.partnerName}}</p>
            </li>
            <li>
              <p class="name">{{card}}</p>
              <p class="con">{{info.cardNo}}</p>
            </li>
            <li>
              <p class="name">{{img}}</p>
              <p style="display: inline-block">
                <span class="link" @click="showBigImg" >查看</span>
              </p>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="12" >
        <div class="card">
          <ul v-show="info.partnerName">
            <li>
              <p class="name">{{cardName}}</p>
              <p class="con">{{info.partnerName}}</p>
            </li>
            <li>
              <p class="name">开户银行</p>
              <p class="con">{{info.bankName}}</p>
            </li>
            <li>
              <p class="name">银行账号</p>
              <p class="con">{{info.bankCardNo}}</p>
            </li>
          </ul>
          <p class="link" v-show="info.partnerName" @click="openBank">修改收款银行卡信息</p>
        </div>
      </el-col>
    </el-row>
    <div class="apply-btn-box">
      <el-button size="small" @click="openAdd" type="primary" >申请新推广ID</el-button>
    </div>
    <div class="table-box">
      <div class="table-box">
        <table>
          <thead>
            <tr>
              <th class="left" >推广名称</th>
              <th style="width: 200px;">推广ID</th>
              <th style="width: 200px;">推广链接</th>
              <th style="padding-right: 62px; width: 160px;" class="right">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in list" :key="val.id">
              <td class="left">{{val.title}}</td>
              <td>{{val.spreadId}}</td>
              <td>
                <span class="link" @click="check(val)">查看</span>
              </td>
              <td class="right">
                <span class="link" @click="openEdit(val)" >编辑</span>
                <el-divider direction="vertical"></el-divider>
                <span class="link" @click="remove(val)">删除</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="no-data" v-if="list.length === 0" >
          暂无数据
        </div>
      </div>
    </div>

    <el-dialog
      title="申请新推广ID"
      :visible.sync="showAddDialog"
      width="600px"
      custom-class="flex-dialog editor-dialog-style"
      :close-on-click-modal="false"
    >
      <div class="info-dialog-box">
        <el-form ref="form" :model="addForm" label-position="left" label-width="80px">
          <el-form-item label="推广名称">
            <el-input v-model="addForm.name" maxlength="20" placeholder="推广名称" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showAddDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addNewChannel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改推广名称"
      :visible.sync="showEditDialog"
      width="600px"
      custom-class="flex-dialog editor-dialog-style"
      :close-on-click-modal="false"
    >
      <div class="info-dialog-box">
        <el-form ref="form" :model="editForm" label-position="left" label-width="80px">
          <el-form-item label="推广ID">
            {{editForm.spreadId}}
          </el-form-item>
          <el-form-item label="推广名称">
            <el-input v-model="editForm.title" maxlength="20" placeholder="推广名称" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showEditDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="editChannel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改银行卡信息"
      :visible.sync="showBankDialog"
      width="600px"
      custom-class="flex-dialog editor-dialog-style"
      :close-on-click-modal="false"
    >
      <div class="info-dialog-box">
        <el-form ref="form" :model="bankForm" label-position="left" label-width="100px">
          <el-form-item :label="cardName">
            {{info.partnerName}}
          </el-form-item>
          <el-form-item label="开户银行">
            <el-input v-model="bankForm.name" maxlength="50"  placeholder="开户银行" ></el-input>
          </el-form-item>
          <el-form-item label="银行账号">
            <el-input v-model="bankForm.no" maxlength="25"  placeholder="银行账号" ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="showBankDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="editBank">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showSpreadDialog"
      width="700px"
      custom-class="flex-dialog editor-dialog-style"
      :close-on-click-modal="false"
    >
      <div style="padding: 5px 0; text-align: center" slot="title">
        <el-radio-group v-model="type">
          <el-radio-button label="link">推广链接</el-radio-button>
          <el-radio-button label="wechat">微信小程序</el-radio-button>
        </el-radio-group>
      </div>
      <div>
        <div class="type-link" v-if="type === 'link'">
          <div class="link-box" v-for="(val, index) in baseInfo" :key="index">
            <div class="link-header">
              <span class="link" @click="windowOpen(baseUrl+val.key+'?p='+shareKey)">{{baseUrl+val.key+'?p='+shareKey}}</span>
              <span class="copy" @click="copy(baseUrl+val.key+'?p='+shareKey,val)">复制</span>
            </div>
            <div class="link-con">
              <h3>{{val.title}}</h3>
              <p>{{val.description}}</p>
            </div>
          </div>
          <div class="tips">
            你可以把上面的任一推广链接发送给朋友或者发布到别的网站，首次访问网站的用户通过该链接访问注册后，该用户会记录为你的推广用户。
          </div>
        </div>
        <div class="type-wechat" v-if="type === 'wechat'">
          <div class="wechat-box">
            <img :src="qrUrl" alt="">
            <div class="wechat-btn">
              <el-button style="width: 150px;" @click="qrCodeDialogVisible = true" >二维码美化</el-button>
              <el-button style="margin-left: 16px; width: 150px;" type="primary" @click="downloadFile" >下载二维码</el-button>
            </div>
            <div class="tips">
              把该二维码分享到微信群、朋友圈、微信好友，用户长按识别会打开「互联二维码」小程序，在60天内如果有注册账号，则记录为你的推广用户。
            </div>
            <!-- <h3>说明：</h3>
            <p>把该二维码分享到微信群、朋友圈、微信好友，用户长按识别会打开「互联二维码」小程序，在60天内如果有注册账号，则记录为你的推广用户。</p> -->
          </div>
        </div>
      </div>
    </el-dialog>

    <qr-dialog
      v-if="qrCodeDialogVisible"
      :visible="qrCodeDialogVisible"
      @on-cancle="handleCancleDialog"
      @finish="finish"
    />

    <canvas ref="canvas" style="display: none;"></canvas>

    <image-view v-if="showImg" :on-close="() => {showImg = false}" :url-list="imgList"></image-view>

  </div>
</template>

<script>
import {getPartner, getSpreadList, addSpread, editSpread, deleteSpread, addBank} from '@/api'
import {styleCreate} from '@/api/beautify'
import qrDialog from './main/qrDialog'
import {SEO} from '@/assets/js/seo'
import imageView from 'element-ui/packages/image/src/image-viewer.vue'

export default {
  name: "info",
  data(){
    return {
      baseUrl: process.env.VUE_APP_INDEX_URL,
      baseInfo: SEO,
      shareImgContent: "",
      type: "link",
      shareKey: "",
      info: {},
      list: [],
      showAddDialog: false,
      showEditDialog: false,
      showBankDialog: false,
      showSpreadDialog: false,
      qrCodeDialogVisible: false,
      addForm: {
        name: ""
      },
      editForm: {
        title: "",
        spreadId: "",
        id: ""
      },
      bankForm: {
        name: "",
        no: ""
      },
      showImg: false,
      imgList: []
    }
  },
  components: {
    qrDialog, imageView
  },
  computed:{
    cardName(){
      return ['收款人姓名','企业名称'][this.info.partnerType]
    },
    name(){
      return ['个人姓名','企业名称'][this.info.partnerType]
    },
    card(){
      return ['身份证号','统一社会信用代码'][this.info.partnerType]
    },
    img(){
      return ['身份证照片','营业执照照片'][this.info.partnerType]
    },
    qrUrl: {
      get() {
        return this.$store.state.main.qrUrl
      },
      set(value) {
        this.$store.commit('main/SET_QR_URL', value)
      }
    },
    qrInfo: {
      get() {
        return this.$store.state.main.qrInfo
      },
      set(value) {
        this.$store.commit('main/SET_QR_INFO', value)
      }
    }
  },
  mounted(){
    this.getData()
    this.getList()
  },
  methods: {
    showBigImg(){
      this.imgList = [this.info.cardNoSrc]
      this.showImg = true
    },
    copy(url, val){
      var oInput = document.createElement('textarea');
      oInput.value = `链接：${url}
${val.title}
${val.description}`;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = 'oInput';
      oInput.style.display='none';
      this.$message.success('复制成功')
    },
    download(url, name){ // 下载
      let canvas = this.$refs.canvas
      let ctx = canvas.getContext('2d')
      let img = new Image()
      img.crossOrigin = "anonymous"
      img.src = url
      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img,0,0,canvas.width, canvas.height)
        let result = canvas.toDataURL()
        let a = document.createElement('a')
        let event = new MouseEvent('click')
        a.download = name + '.png'
        a.href = result
        a.dispatchEvent(event)
      }
    },
    downloadFile(){
      this.download(this.qrUrl, "share")
    },
    handleCancleDialog(){
      this.qrCodeDialogVisible = false
    },
    finish(url){
      this.qrCodeDialogVisible = false
      this.qrUrl = url
    },
    windowOpen(url){
      window.open(url)
    },
    check(val){
      this.shareKey = val.spreadId
      this.shareImgContent = process.env.VUE_APP_WX_URL + '?p=' +val.spreadId

      let qrInfoTemp = JSON.parse(JSON.stringify(this.qrInfo))
      if(this.$store.state.hasTemplate){
        qrInfoTemp = JSON.parse(JSON.stringify(this.$store.state.templateInfo))
      }
      qrInfoTemp.content = this.shareImgContent
      qrInfoTemp.format = 1
      this.$store.commit('main/SET_CONTENT', this.shareImgContent)

      styleCreate(qrInfoTemp)
        .then(data => {
          if (data) {
            this.qrUrl = data
            this.showSpreadDialog = true
          }
        })
        .catch(e => {
          // this.qrLoading = false
          // this.beautifyQrDisabled = false
          throw new Error(e)
        })
    },
    editBank(){
      let reg = /^(\d){10,20}$/
      if(this.bankForm.name.length === 0){
        this.$message.error("请输入银行名称")
        return
      }
      if(!reg.test(this.bankForm.no)){
        this.$message.error("银行账号错误")
        return
      }
      addBank({
        bankCardNo: this.bankForm.no,
        bankName: this.bankForm.name
      }).then(() => {
        this.$message.success("银行卡信息修改成功")
        this.getData()
        this.showBankDialog = false
      })
    },
    openBank(){
      this.bankForm.name = this.info.bankName
      this.bankForm.no = this.info.bankCardNo
      this.showBankDialog = true
    },
    remove(val){
      this.$confirm('确定删除？删除后该推广推广未结算的佣金将不能在结算。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSpread({
          id: val.id
        }).then(() => {
          this.$message.success("删除成功")
          this.getList()
        })
      }).catch(() => {
              
      });
    },
    editChannel(){
      editSpread({
        id: this.editForm.id,
        title: this.editForm.title
      }).then(() => {
        this.getList()
        this.showEditDialog = false
      })
    },
    openEdit(val){
      this.editForm.title = val.title
      this.editForm.id = val.id
      this.editForm.spreadId = val.spreadId
      this.showEditDialog = true
    },
    openAdd(){
      this.addForm.name = ""
      this.showAddDialog = true
    },
    addNewChannel(){
      if(this.addForm.name === ""){
        this.$message.error("请填写推广名称")
        return
      }
      addSpread({
        id: 0,
        title: this.addForm.name
      }).then(() => {
        this.getList()
        this.showAddDialog = false
      })
    },
    getData(){
      getPartner().then(resp => {
        this.info = resp
      })
    },
    getList(){
      getSpreadList().then(resp => {
        this.list = resp
      })
    }
  }
}
</script>

<style lang="scss">
.type-wechat{
  padding: 40px 30px;
  img{
    display: block;
    margin: 0 auto auto auto;
    width: 240px;
    height: 240px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 19, 11, 0.15);
  }
  .wechat-btn{
    padding: 24px;
    text-align: center;
  }
  .tips{
    margin-top: 13px;
    padding: 16px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    background: #FDFCFC;
    color: #666;
    font-size: 13px;
    line-height: 18px;
  }
  h3{
    margin-top: 15px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }
  p{
    margin-top: 10px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }
}
.type-link{
  padding: 21px;
  .link-box{
    margin-top: 13px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    &:first-child{
      margin-top: 0;
    }
  }
  .tips{
    margin-top: 13px;
    padding: 16px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    background: #FDFCFC;
    color: #666;
    font-size: 13px;
    line-height: 18px;
  }
  .link-header{
    position: relative;
    padding: 17px 14px;
    background: #FDFCFC;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e1e1e1;
    line-height: 1;
    .copy{
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 68px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .link-con{
    padding: 14px;
    h3{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    p{
      margin-top: 10px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
 
}
 
.info-dialog-box{
  padding: 40px 30px 20px;
}
.page-info{
  .card{
    padding: 14px 24px;
    height: 180px;
    background: #fff;
    li{
      padding: 10px 0;
      // display: flex;
    }
    .con,.name{
      display: inline-block;
    }
    .name{
      width: 150px;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      color: #666666;
    }
    .con{
      // flex-grow: 1;
      max-width: calc(100% - 150px);
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      line-height: 1;
      @include textOverflow;
    }
    .avatar{
      // display: block;
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 4px;
      border: 1px solid #e7e7e7;
      cursor: pointer;
      vertical-align: top;
    }
    .link{
      margin-top: 10px;
      color: $link;
      cursor: pointer;
    }
  }
  .apply-btn-box{
    padding: 24px 0 0;
  }
  .table-box{
    margin-top: 24px;
    table{
      width: 100%;
      @include tableBorder;
      border: 1px solid #e7e7e7;
      td,th{
        height: 49px;
        text-align: center;
      }
      td{
        background: #fff;
        border-top: 1px solid #e7e7e7;
      }
      th{
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        opacity: 0.89;
        background: #f7f7f7;
      }
      .left{
        padding-left: 20px;
        text-align: left;
      }
      .right{
        padding-right: 40px;
        text-align: right;
      }
    }
    .no-data{
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #fff;
      border: 1px solid #e7e7e7;
      border-top: none;
    }
  }
  .link{
    color: $link;
    cursor: pointer;
  }
}
</style>